import React from 'react'
import NavBar from './navbar'



const AppNavBar = ({children}) => {
  //This allows us to add different top banners behind the same nav bar.

        return (
          <>
            {children}
            <NavBar />
          </>
      )

}

  
export default AppNavBar

