import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby-plugin-modal-routing-3'
import { LinkedInIcon, TwitterIcon, InstagramIcon } from './Buttons'




const NavItem = styled(Link)`
  position: relative;
  text-decoration: none;
  color: black;
  width:
  transition: all 200ms ease-in;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  text-transform: none;
  margin: 0;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :not(p):hover {
    color: var(--tuva-orange-color);
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 0.75em;
  }
`

const Foot = styled.div `
position: relative;
padding: 20px;
border-top: 1px solid black;
bottom: 0;
display: flex;
flex-wrap: wrap;
margin: 30px;
justify-content: space-around;
align-items: center;
`
const Icons = styled.div `
  display: flex;
  justify-content: space-around;

  *{
    fill: #000;

  :hover {
   
    fill: var(--tuva-orange-color);
    transform: scale(1.05);
    transition: 0.2s;
    }
  }

`

const Footer = () => {

  return (
      <Foot>
        <NavItem as="p">© 2021 Tuva Partners Ltd.</NavItem>
        <NavItem to="/cookie-policy" asModal>Cookie Policy</NavItem>
        <NavItem to="/privacy-policy" asModal>Privacy Policy</NavItem>
        <NavItem to="/careers">Careers</NavItem>
        <NavItem to="/contact">Contact Us</NavItem>
        <Icons>
          <LinkedInIcon />
          <TwitterIcon />
          <InstagramIcon />
        </Icons>
      </Foot>
    )
}

export default Footer