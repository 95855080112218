import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { CSSTransition } from "react-transition-group"
import { LinkedInIcon, TwitterIcon, InstagramIcon } from "./Buttons"
import Tuva from "../../../static/assets/tuva-nav-logo.svg"
import TuvaSolid from "../../../static/assets/tuva-nav-logo-solid.svg"

const StickyBox = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 10;
  width: 100vw;
`

const NavBox = styled.div`
  position: relative;
  height: 50px;
  display: grid;
  grid-template-columns: 70% 30%;

  .grad-1 {
    background: linear-gradient(90deg, #ffbc00 0%, #fcad0a 100%);
    background-image: -webkit-linear-gradient(0deg, #ffbc00 0%, #fcad0a 100%);
    height: 50px;
  }

  .grad-3 {
    height: 50px;
    width: 100%;
    background: linear-gradient(90deg, #fa9f13 0%, #f78d1e 100%);
    background-image: -webkit-linear-gradient(0deg, #fa9f13 0%, #f78d1e 100%);
    margin-left: -1px;
  }

  .no {
    background: unset;
    background-image: unset;
    background-color: var(--tuva-orange-color);
  }

  @media (max-width: 598px) {
    grid-template-columns: 50% 50%;
  }
`

const Toggle = styled.div`
  display: flex;
  height: 100%;
  width: fit-content;
  cursor: pointer;
  margin-left: 2vw;
`

const Menu = styled.div`
  height: fit-content;
  position: absolute;
  width: 100%;
  color: #fff;

  .grad {
    padding: 10px 0;
    background: linear-gradient(
      90deg,
      #ffbc00 0%,
      #fcad0a 70%,
      #fa9f13 80%,
      #f78d1e 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      #ffbc00 0%,
      #fcad0a 70%,
      #fa9f13 80%,
      #f78d1e 100%
    );
    background-color: var(--tuva-orange-color);
  }

  .no {
    background: unset;
    background-image: unset;
    background-color: var(--tuva-orange-color);
  }

  &.menu-open-enter {
    left: -110%;
  }
  &.menu-open-enter-active {
    left: 0%;
    transition: left 300ms;
  }
  &.menu-open-exit {
    left: 0%;
  }
  &.menu-open-exit-active {
    left: -110%;
    transition: left 300ms;
  }

  a {
    display: block;
    width: fit-content;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.25em 0;
    color: #fff;
    margin-left: 22px;

    &:hover {
      font-weight: 600;
      color: #000;
      transition: all 0.3s;
    }
  }
`

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const Icons = styled.div`
  display: flex;
  margin-left: 22px;

  & a:nth-child(1) {
    margin-left: -0.75em;
  }

  & * {
    fill: #fff;

    &:hover {
      fill: #000;
      transition: 0.3s;
    }
  }
`
const NavBar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const navbar = useRef()
  const [safari, isSafari] = useState(false)

  useEffect(() => {
    if (
      !!navigator.userAgent.match(/safari/i) &&
      !navigator.userAgent.match(/chrome/i) &&
      typeof document.body.style.webkitFilter !== "undefined" &&
      !window.chrome
    ) {
      isSafari(true)
    }
  }, [safari])

  // Nav menu logic
  const toggle = e => {
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
      navbar.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        passive: "true",
      })
    }, 100)
  }
  return (
    <StickyBox>
      <NavBox>
        <div className={`grad-1 ${safari ? "no" : ""}`}>
          <Toggle onClick={toggle} ref={navbar}>
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
        </div>
        <div style={{ display: "flex" }}>
          <Link to="/#">{safari ? <TuvaSolid /> : <Tuva />}</Link>
          <div className={`grad-3 ${safari ? "no" : ""}`} />
        </div>
      </NavBox>
      <CSSTransition
        in={navbarOpen}
        timeout={200}
        classNames="menu-open"
        unmountOnExit
        onEnter={() => setNavbarOpen(true)}
        onExited={() => setNavbarOpen(false)}
      >
        <Menu onClick={() => setNavbarOpen(!navbarOpen)}>
          <div className={`grad ${safari && "no"}`}>
            <Link to="/">HOME</Link>
            <Link to="/services">SERVICES</Link>
            <Link to="/our-work">OUR WORK</Link>
            <Link to="/insights">INSIGHTS</Link>
            <Link to="/careers">CAREERS</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/contact">CONTACT US</Link>
            <Icons>
              <LinkedInIcon />
              <TwitterIcon />
              <InstagramIcon />
            </Icons>
          </div>
        </Menu>
      </CSSTransition>
    </StickyBox>
  )
}

NavBar.propTypes = {
  navbarOpen: PropTypes.bool,
  landingImage: PropTypes.node,
}

export default NavBar
